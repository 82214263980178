<template>
    <!-- Page Content -->
    <div class="container" id="container">
      <div class="col-lg-12 mb-12">
        <div class="text-left mx-auto" style="max-width: 600px">
          <div>アンケートAが完了しました。</div>
          <div v-if="!research.sampleExpireFlg">採取予定日、試料回収希望日時は以下で承りました。</div><br>
          <div v-if="!research.sampleExpireFlg" class="form-group row m-0 p-0 text-center text-sm-left">
            <label class="col-sm-3 col-form-label p-sm-1">採取予定日</label>
            <div class="col-sm-6 mx-auto">
            {{ day1 }}
            </div>
          </div><br><br>
          <div v-if="!research.sampleExpireFlg" class="form-group row m-0 p-0 text-center text-sm-left">
            <label class="col-sm-3 col-form-label p-sm-1">試料回収希望日時</label>
            <div class="col-sm-6 mx-auto">
              <div v-for="(d2, idx) in day2" v-bind:key="d2" class="mb-5">
                <div class="mb-2">{{ getFormatDate(chars8ToDate(idx)) }}</div>
                <div v-for="d in d2" v-bind:key="d">{{ getFormatTime(d) }}</div>
              </div>
            </div>
          </div>
          <div v-if="!research.sampleExpireFlg">採取予定日前日にメールでご連絡いたします。</div>
          <div>採取予定日前日になったら、再度ログインをお願いいたします。</div>
        </div>
      </div>
    </div>
</template>
<script>
import { Mixin } from '@/mixin.js'

export default {
  mixins: [Mixin],
  data() {
    return {
      day1: null,
      day2: [],
    }
  },
  mounted() {
    try {
      this.sampleDay = localStorage.getItem('sampleDay')
      if (!(
        parseInt(this.research.sampleStartDate) <= parseInt(this.sampleDay) &&
        parseInt(this.sampleDay) <= parseInt(this.research.sampleEndDate)
      )) {
        throw new Error()
      }
      this.schedules = JSON.parse(localStorage.getItem('collectionDays'))
    } catch {
      this.sampleDay = null
      this.schedules = null
    }

    this.research.days = this.getCandidateDates(this.research.startDate, this.research.endDate)
    this.research.sampleDays = this.getCandidateDates(this.research.sampleStartDate, this.research.sampleEndDate)

    if (this.sampleDay) this.day1 = this.getFormatDate(this.chars8ToDate(this.sampleDay))
    let nowDate
    if (this.schedules) this.day2 = this.schedules.reduce((arr, date) => {
      const getDate = date.slice(0, 8)
      if (nowDate != getDate) {
        nowDate = getDate
        arr[nowDate] = []
      }
      arr[nowDate].push(date)
      return arr
    }, {})
    this.resetToken()
  },
}
</script>
<style scoped>
div#wallet{
  margin-top: 30px;
}
table{
  width: 40%;
  margin: 0 auto;
}
th{
  background-color: #007db9;
  color: #fff;
}
th, td{
  padding: 5px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
#detail-btn button{
  cursor: pointer;
}
td {
  font-size:small;
}
</style>
